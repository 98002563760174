export enum ReportTypeENUM {
  Custom = 1,
  SalesDetails = 2,
  CampaignSummary = 3,
  NewToAdvertiser = 4,
  SalesChannel = 5,
  SalesPerformance = 6,
  SitePerformance = 7,
  SellPerformance = 8,
  MeasurementStudy = 9,
}

export type GetReportResponse = {
  id: number
  name: string
}

export type DuplicateReportResponse = {
  logId: number
  originalId: number
  userId: number | null
  requestId: number | null
  reportMta: true
  accountId: number | null
  name: string
  account: string | null
  reportTypeId: ReportTypeENUM
  reportJSON: string
  customReportSchedule: string | null
  updatedDate: string
  createdDate: string
  updatedBy: string
  createdBy: string
  statusId: number
  id: number
}
