import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { DuplicateReportResponse, GetReportResponse } from '../types'

export class ReportingService extends ApiService {
  getReport(reportId: number): AxiosPromise<GetReportResponse> {
    return this.request({
      url: `api/reports/customreport/${reportId}`,
      method: 'GET',
    })
  }

  deleteReport(reportId: number): AxiosPromise<void> {
    return this.request({
      url: `api/reports/customreport/${reportId}`,
      method: 'DELETE',
    })
  }

  deleteExport(deliveryId: number): AxiosPromise<void> {
    return this.request({
      url: `api/reports/customreport/cleanup/${deliveryId}`,
      method: 'POST',
    })
  }

  duplicateReport(reportId: number): AxiosPromise<DuplicateReportResponse> {
    return this.request({
      url: `api/reports/customreport/copy/${reportId}`,
      method: 'POST',
    })
  }
}
